@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import url("https://fonts.googleapis.com/css2?family=Aboreto&family=Poiret+One&display=swap");

body {
  background-color: #f5f5f5;
  font-family: "Poiret One", cursive;
  font-size: 1rem;
}

/* Change the scrollbar style */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 90%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}

/* Arrow from gallery */
.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 2rem;
}
