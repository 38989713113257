.ModalBody {
  width: 100vw;
  height: 100vh;
  /* gray */
  background-color: rgba(0, 0, 0, 0.703);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.card {
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  width: 70vh;
}

.card .top {
  text-align: center;
}

.card p {
  font-weight: bold;
  color: #38b2ac;
}

.card button {
  border: none;
  outline: none;
  background: #38b2ac;
  color: #fff;
  padding: 8px 13px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

.card .drag-area {
  height: 300px;
  border-radius: 5px;
  border: 3px dashed #38b2ac;
  color: #38b2ac;
  background: #f4f3f9;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
  padding: 40px;
}

.card .drag-area .visible {
  font-size: 18px;
}

.card .select {
  color: #5256ad;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}

.card .select:hover {
  opacity: 0.6;
}

.card .container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}

.card .container .image {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  position: relative;
}

.card .container .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.card .container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
  display: none;
}

.delete {
  z-index: 1000;
  color: #38b2ac;
}

.close-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.close-button {
  color: #38b2ac;
  cursor: pointer;
  font-weight: bold;
}
